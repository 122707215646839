<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <div class="g col-12 text-center"><button class="btn btn-primary" v-b-modal.modal-1 @click="current = {}"><i class="fa fa-plus"></i> اضافة رد</button></div>
            <br>
            <table class="table table-hover table-bordered">
                <tbody>
                    <tr v-for="tag in tags" :key="tag._id">
                      <td @click="current = tag;" v-b-modal.modal-1>
                          {{ tag.body }}
                      </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="modal-1" title="" hide-footer>
        <div class="form-group">
          <h5 for="">الرد</h5>
          <textarea type="text"
            class="form-control" v-model="current.name" rows="5"></textarea>
        </div>
        <div class="col-12 g">
          <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="save()">
              حفظ
            </button>
            <div v-if="current._id">
              <br><br>
              <button class="btn btn-sm btn-outline-danger" @click="deleteAdmin(current._id)">
                <i class="fa fa-trash"></i>
                حذف القالب
              </button>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  created(){
    var g = this;
    g.getAdmins()
  },
  data(){
    return {
        jwt: JSON.parse(localStorage.getItem("user")).jwt,
        tags: [],
        current: {
        },
    }
  },
  methods: {
    getAdmins(){
      var g = this;
      $.post(api + '/user/templates/list', {
          jwt: this.jwt
      }).then(function(r){
          if(r.status != 100){
              alert(r.response)
          }else{
              g.tags = r.response
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
    },
    save(){
      var g = this;
      $.post(api + '/user/templates/save', {
          jwt: this.jwt,
          id: this.current._id,
          body: this.current.name,
          
      }).then(function(r){
          if(r.status != 100){
              alert(r.response)
          }else{
              $("#modal-1___BV_modal_header_ > button").click()
              g.getAdmins()
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
    },
    deleteAdmin(id){
      if(confirm("متاكد من حذف الرد؟")){
        var g = this;
        $.post(api + '/user/templates/delete', {
            jwt: this.jwt,
            id: id
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                $("#modal-1___BV_modal_header_ > button").click()
                g.getAdmins()
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
      }
    }
  }
}
</script>

<style>
.ss:hover{
    background: #eee;
    cursor: pointer;
}
.ss.active{
    background: #ddd;
}
</style>